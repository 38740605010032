import 'jquery-ui/ui/widgets/datepicker';

var $ = require("jquery");

window.$ = $;
window.jQuery = $;
global.$ = $;
global.jQuery = $;

$(document).ready(function () {
    // $('#content').bootstrapMaterialDesign();
});
